
import Vue from 'vue';
import { GaType } from '../../../../store/modules/linearadmin/types/google';

export default Vue.extend({
  data: (): {
    isSortedList: boolean;
    sortedViews: Array<object> | null;
  } => ({
    isSortedList: true,
    sortedViews: null,
  }),
  props: ['views', 'property', 'account', 'selectedGA'],
  methods: {
    getSortedViews(views: Array<Record<string, string>>): Array<GaType> {
      if (!this.views?.length) return [];
      if (!this.isSortedList) return this.sortedViews;
      // the intent is to sort alphabetically and return the selected element first in list
      let selectedView;

      views = views.filter((view: { id: string }) => {
        // remove selected view if found
        // only perform this when created to avoid re-sorting when you click
        if (this.isSortedList && this.isPropertyChecked(view.id)) {
          selectedView = view;
        } else return view;
      });
      // add to the beginning of the list
      if (selectedView) views.unshift(selectedView);

      // create a local variable to store the sorted list
      this.sortedViews = views;
      this.isSortedList = false;
      return this.sortedViews;
    },
    isPropertyChecked(viewId: string): boolean {
      if (this.selectedGA?.viewId) {
        return this.selectedGA.viewId === viewId;
      } else return false;
    },
    propertyCheckedClass(propertyId: string, viewId: string): string {
      return this.isPropertyChecked(viewId) ? 'green--text' : 'grey--text text--lighten-2';
    },
    selectView(accountId: string, propertyId: string, viewId: string): void {
      this.isSortedList = false;
      this.$emit('select-view', accountId, propertyId, viewId);
    },
  },
});
